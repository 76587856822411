module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[{"resolve":"/opt/build/repo/node_modules/gatsby-remark-images","id":"1c4c0e22-06f7-56ca-92df-5ff556b32f65","name":"gatsby-remark-images","version":"3.2.5","pluginOptions":{"plugins":[],"maxWidth":1440,"linkImagesToOriginal":false,"withWebp":true,"backgroundColor":"transparent"},"nodeAPIs":[],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]}],"extensions":[".md",".mdx"],"defaultLayouts":{"default":"/opt/build/repo/node_modules/gatsby-theme-catalyst-core/src/components/layout.js"},"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-relative-images","options":{"name":"images"}},{"resolve":"gatsby-remark-images","options":{"maxWidth":1440,"linkImagesToOriginal":false,"withWebp":true,"backgroundColor":"transparent"}},{"resolve":"gatsby-remark-copy-linked-files","options":{"destinationDir":"content/assets"}},{"resolve":"gatsby-remark-smartypants"},{"resolve":"gatsby-remark-reading-time"},{"resolve":"gatsby-remark-responsive-iframe"},{"resolve":"gatsby-remark-external-links"}],"remarkPlugins":[null]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1440,"linkImagesToOriginal":false,"withWebp":true,"backgroundColor":"transparent"},
    },{
      plugin: require('../node_modules/@pauliescanlon/gatsby-mdx-embed/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-theme-catalyst-core/gatsby-browser.js'),
      options: {"plugins":[],"useStickyHeader":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"obaly-sacky-rychle-kvalitne","short_name":"obalyrak","start_url":"/","background_color":"#ffffff","theme_color":"#cccccc","display":"minimal-ui","icon":"content/assets/catalyst-site-logo.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-PQF7237","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}}},
    }]
