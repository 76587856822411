import { merge } from "theme-ui"
import { BaseTheme } from "gatsby-theme-catalyst-core"

export default merge(BaseTheme, {
  colors: {
    background: "white",
    modes: {
      dark: {
        background: "#1F1F1F",
      },
    },
  },
  variants: {
    siteTitle: {
      fontSize: [3, 4, null, 5, null],
    },
    main: {
      fontSize: [1, 1, 1, 1, 1],
    },
    footer: {
      fontSize: [1, 1, 1, 1, 1],
    },
    contentContainer: {
      fontSize: [1, 1, 1, 1, 1],
    },
  },
})
